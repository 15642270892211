import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import celenaservices from "../services/celenaservices";
import { useUserRole } from "./UserContext";
import TypeWriterEffect from "./TypeWriterEffect";
import { Typography } from "@mui/material";

  
function QueryData () {
  const { userRole } = useUserRole();
    const [userprompt, setUserprompt] = useState('');
    const [chatgptresult, setChatGptResult] = useState('Chatgpt result...');
    
      const GetChatGptText = async () => {
        var result=await celenaservices.ChatGptData(userprompt).then(res => { return res.data })
        setChatGptResult(result)
        
      }
      const GetChatGptTextfromRag = async () => {
        var result=await celenaservices.llmquery(userprompt).then(res => { return res.data.result })
        setChatGptResult(result)
      }
      const GetProduct = async () => {
        var result=await celenaservices.llmproduct(userprompt).then(res => { return res.data.result })
        setChatGptResult(result)
      }
      const GetRoster = async () => {
        var result=await celenaservices.llmroster(userprompt).then(res => { return res.data.result })
        setChatGptResult(result)
      }
      const DocQnA = async () => {
        var result=await celenaservices.DocQnA(userprompt).then(res => { return res.data.result })
        setChatGptResult(result)
      }
      const DocSummarizer = async () => {
        var result=await celenaservices.DocSummarizer(userprompt).then(res => { return res.data.result })
        setChatGptResult(result)
      }
            return <div style={{
              display: 'grid',
              gap: '20px',
              padding: '20px',
            }}>
       
      <div><TextField id="standard-basic" label="Prompt" variant="outlined" fullWidth value={userprompt} onChange={e => setUserprompt(e.target.value)}/>
        </div>
    
        <div style={{        
          display: 'flex',
          gap: '20px',

        }}>
          
         
        <Button variant="outlined" onClick={GetChatGptText} 
       >Get Response from Chat GPT</Button>
  
        <Button variant="outlined" onClick={GetChatGptTextfromRag} 
        >Get Fee Schedule</Button>
    
        <Button variant="outlined" onClick={GetProduct}
    
        >Get Products</Button>
     
        <Button variant="outlined" onClick={GetRoster}
     
        >Get Rosters</Button>
    
        <Button variant="outlined" onClick={DocQnA}
      
        >Doc QnA</Button>
       
        <Button variant="outlined" onClick={DocSummarizer}
   
        >Doc Summarizer</Button>
        
        </div>
        
       
      {/* <div dangerouslySetInnerHTML={{ __html: userprompt }} /> */}
      <Typography sx={{
        fontWeight: '600',
      }}>LLM Response:</Typography>
      
      {/* TYPE OF TEXT FIELDS BELOW  */}

      {/* <TextField multiline
                rows={5} id="standard-basic" label="Result" variant="outlined" fullWidth value={chatgptresult} /> */}
      <TextField 
      variant="standard"
      InputProps={{
             disableUnderline: true,
           }}
           fullWidth
       multiline
                rows={5} id="standard-basic" label="Result"  value={chatgptresult} />    
      {/* <TypeWriterEffect fileContext={chatgptresult} speed={1}></TypeWriterEffect> */}

      
    </div>
    
}
export default QueryData;