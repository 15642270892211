import OneIntakeRequest from "../types/oneIntakeTypes";
import celenaapi from "./celenaapi";
import operaapp from "./operaapp";


const ChatGptData=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/AzureOpenAIChatBot',data,options)
};
const llmquery=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/LlmQuery',data,options)
}
const llmroster=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/LlmQueryRoster',data,options)
}
const DocQnA=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/QnA',data,options)
}
const DocSummarizer=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/DocSummary',data,options)
}
const llmproduct=(prompt:string):Promise<any>=>{
    const data=JSON.stringify({prompt})
    const options={
     //   method:'POST',
        headers:{'Content-Type':'application/json'}
       // body:data
    }
    return celenaapi.post('/api/LlmQueryProduct',data,options)
}
const generateJwtToken = (usercredentials?: any): Promise<any> => {
    const data1 = JSON.stringify(usercredentials);
    const options = {
      headers: { "content-type": "application/json" },
    };
    return operaapp.post("User/GenerateJwtToken", data1, options);
  };
  const parseToken = (jwttoken?: any): Promise<any> => {
    const data1 = JSON.stringify(jwttoken);
    const options = {
      headers: { "content-type": "application/json" },
    };
    return operaapp.post("User/ParseToken", data1, options);
  };
  const uploadPdf = (formData?: any): Promise<any> => {
    //const data1 = JSON.stringify(jwttoken);
    const options = {
      headers: { "content-type": "multipart/form-data" },
      //body: formData,
    };
    return celenaapi.post("api/UploadPdf", formData,options);
  };

  
  const ChatOneIntake = (data : OneIntakeRequest):Promise<any>=>{
    
    const options={
     //   method:'POST',
        withCredentials: true,
        headers:{'Content-Type':'application/json',
        },
        
       // body:data
    }
    return celenaapi.post('/api/oneIntake',data,options)
};

  

const celenaservices={
    ChatGptData,
    llmquery,
    llmroster,
    llmproduct,
    DocQnA,
    DocSummarizer,
    generateJwtToken,
    parseToken,
    uploadPdf,
    ChatOneIntake
}
export default celenaservices