import React, { useState } from 'react';
import { Box, Button, Container, FormControl, TextField, Typography,Divider, Input } from '@mui/material';
import celenaservices from "../services/celenaservices";
import TypeWriterEffect from './TypeWriterEffect';

const AddData: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [llmquery, setLlmquery] = useState('');
  const [llmResult, setLlmResult] = useState('');
  const [csvfileContent, setCsvFileContent] = useState('');
  const [keyphrasesContent, setKeyPhrasesContent] = useState('');
  const [summaryContent, setSummaryContent] = useState('');
  const [filteredContent, setfilteredContent] = useState('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      /*const response = await fetch('http://127.0.0.1:5000/api/AnalyzePdf', {
        //const response = await fetch('http://localhost:5000/api/UploadDocument', {
        method: 'POST',
        body: formData,
      });*/
      const response_data=await celenaservices.uploadPdf(formData).then(res => { return res.data })
      //debugger;
      if (response_data) {
        //const data = await response.json();
        setFileContent(response_data[0].text);
        setCsvFileContent(response_data[0].llm_response);
        setKeyPhrasesContent(response_data[0].key_phrases);
        setSummaryContent(response_data[0].doc_summary);
        setfilteredContent(response_data[0].filtered_text)
        console.log('File uploaded successfully!');
      } else {
        console.error('Error uploading file:', response_data.statusText);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploading(false);
    }
  };
  const AddDataToDB = async () => {
    console.log('AddDataToDB');  
    
  }
                                                  
  return (
    <div style = {{
      width: '100%',
      display: 'grid',
      gap: '20px',
      padding: '20px',
   
    }}>
      {/* <FormControl sx={{m:1,width:'80%'}}> */}
      <div style={{
        width: '80%',
        display: 'flex',
        gap: '20px',
        
      }}>
      <Input type="file" onChange={handleFileChange} />
      </div>
      <div>
      <Button variant="contained" onClick={handleUpload} disabled={!selectedFile || uploading}>
        {uploading ? 'Uploading and Vectorizing...' : 'Upload file and Vectorize'}
      </Button>
      </div>
      <div>
      <Typography variant="h6" gutterBottom>
        PDF content:
      </Typography>
      </div>
      {/* <Box border={1} p={8}>
        {fileContent}
      </Box> */}
      <TypeWriterEffect fileContext={fileContent} speed={2}></TypeWriterEffect>
      
       
      {/* </FormControl> */}
    </div>
  );
};

export default AddData;


