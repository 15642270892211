import React, { useState } from 'react';
import oneintakeservices from '../services/celenaservices';
import { Box, Button } from '@mui/material';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    MessageModel,

  } from "@chatscope/chat-ui-kit-react";
import { Cookies, useCookies } from 'react-cookie';
import OneIntakeRequest from '../types/oneIntakeTypes';
import { AddCircle, AddIcCallTwoTone, Height } from '@mui/icons-material';

const OneIntake: React.FC = () => {
   

    const [messages, setMessages] = useState<MessageModel[]>([{
        message: "Hello, how can I help you today?",
        sender: "GPT",
        direction: "incoming",
        position : "normal",
    }])
    const [typing , setTyping] = useState(false)
    const [cookies, setCookie , removeCookie] = useCookies(["chat_history"]);

    const handleNewChat = () => {
        removeCookie("chat_history")
        setMessages([{
            message: "Hello, how can I help you today?",
            sender: "GPT",
            direction: "incoming",
            position : "normal",
        }])
    }
    
    const handleSend = async (html : string, message : string) => {
        const cookie = new Cookies();
        const chat_history = cookie.get("chat_history");
        const newMessage : MessageModel = {
            message: message,
            sender: "User",
            direction: "outgoing",
            position: "normal"
          }
      
          const newMessages = [...messages, newMessage]
          setMessages(newMessages)
          setTyping(true)
          
          const req : OneIntakeRequest = {"message" : message, "chat_history" : JSON.stringify(chat_history)} 
          oneintakeservices.ChatOneIntake(req).then((response) => {
            setCookie("chat_history" ,response.data.chat_history)
            const newMessage : MessageModel = {
                message: response.data.message,
                sender: "GPT",
                direction: "incoming",
                position: "normal"
              }
          
              setMessages([...newMessages, newMessage])
              setTyping(false)
          }).catch((error) => { 
                console.log(error)
            })
    }

    return (
        <Box  height={"80vh"}>

            <MainContainer>
                <ChatContainer >
                
                    <MessageList
                    scrollBehavior="smooth"
                    style={{textAlign:"left"}}
                    typingIndicator={typing ? < TypingIndicator content="GPT is typing"/> : null}
                    >
                   <Button style={{ float : "right", marginTop : "1%"}} variant="outlined" onClick={handleNewChat} startIcon={<AddCircle/>}> New Chat</Button>
                    {messages.map((message) => (
                        <Message model={message}>
                            <Message.Header> {message.sender}</Message.Header>
                        </Message>
                    )) }
                    </MessageList>
                    
                    <MessageInput    placeholder="Type message here"  onSend={handleSend} />
                  
                </ChatContainer>
                

            </MainContainer>
          
        </Box>
    );
};

export default OneIntake;


