import React, { useState, useEffect, createContext, useContext } from "react";
import celenaservices from "../services/celenaservices";
import LoginDialog from "./LoginDialog";
import { Cookies } from "react-cookie";
interface UserContextProps {
  userRole: string | null;
  setUserRole: React.Dispatch<React.SetStateAction<string | null>>;
  userMsId: string | null;
  setUserMsId: React.Dispatch<React.SetStateAction<string | null>>;
  userEmail: string | null;
  setUserEmail: React.Dispatch<React.SetStateAction<string | null>>;
}
const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [userMsId, setUserMsId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [logindialogOpen, setLoginDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    const cookies = new Cookies();
    const proscoreidentity = cookies.get("proscoreidentity");
    celenaservices.parseToken(proscoreidentity).then((response) => {
      console.log("response.data.userRole", response.data.userRole);
      console.log("response.data.userMsId", response.data.userMsId);
      console.log("response.data.userEmail", response.data.userEmail);
      setUserRole(response.data.userRole);
      setUserMsId(response.data.userMsId);
      setUserEmail(response.data.userEmail);
      setLoginDialogOpen(false);
    });
  };
  const handleSaveLogin = (userId: any, Pwd: any) => {
    console.log("handleSaveLogin");
  };

  useEffect(() => {
    const cookies = new Cookies();
    const proscoreidentity = cookies.get("proscoreidentity");
    if (proscoreidentity === undefined) {
      setLoginDialogOpen(true);
    }
    console.log("proscoreidentity-", proscoreidentity);
    celenaservices.parseToken(proscoreidentity).then((response) => {
      console.log("response.data.userRole", response.data.userRole);
      console.log("response.data.userMsId", response.data.userMsId);
      console.log("response.data.userMsId", response.data.userEmail);
      setUserRole(response.data.userRole);
      setUserMsId(response.data.userMsId);
      setUserEmail(response.data.userEmail);
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        userRole,
        setUserRole,
        userMsId,
        setUserMsId,
        userEmail,
        setUserEmail,
      }}
    >
      <LoginDialog
        open={logindialogOpen}
        onClose={handleCloseDialog}
        DTitle={"Login"}
        TextLabel={"User MS Id"}
      />
      {children}
    </UserContext.Provider>
  );
};
export const useUserRole = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserRole must be used within a UserProvider");
  }
  return context;
};
export const useUserMsId = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserMsId must be used within a UserProvider");
  }
  return context;
};
export const useUserEmail = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserEmail must be used within a UserProvider");
  }
  return context;
};
