import { Typography } from "@mui/material"
import React, { useState, useEffect } from 'react';
import { text } from "stream/consumers";

interface TextProps {
    fileContext: string,
    speed: number,
}

const TypeWriterEffect:React.FC<TextProps> = ({fileContext, speed}) => {
    const useTypeWriter = (fileContext: string, speed: number) => {
        const [displayText, setDisplayText] = useState('');
      
        useEffect(() => {
          let i = 0;
          const typingInterval = setInterval(() => {
            if (i < fileContext.length) {
              setDisplayText(prevText => prevText + fileContext.charAt(i));
              i++;
            } else {
              clearInterval(typingInterval);
            }
          }, speed);
      
          return () => {
            clearInterval(typingInterval);
          };
        }, [fileContext, speed]);
      
        return displayText;
      };
     
    const displayText =  useTypeWriter(fileContext, speed);
    return (
        <Typography style={{
            // fontSize: 18,
        }}>
            {displayText}
        </Typography>
    )
}

export default TypeWriterEffect;