import * as React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AddData from "./component/AddData";
import QueryData from "./component/QueryData";
import { CottageOutlined } from "@mui/icons-material";
import { UserProvider } from "./component/UserContext";
import OneIntake from "./component/OneIntake";

const DrfPages = [
  {
    page: "adddata",
    menudisplay: "Add Data",
    menuicon: "PriceChangeOutlined",
  },
  {
    page: "querydata",
    menudisplay: "Query Data",
    menuicon: "PriceChangeOutlined",
  },
  {
    page: "oneintake",
    menudisplay: "One Intake chat",
    menuicon: "PriceChangeOutlined",
  },
  
];
const openUserDetails = (url: any) => {
  window.open(url, "_blank", "noopener noreferrer");
}
const proshomeurl = "https://copilot.microsoft.com/";

const handleExternalLinkClick = (url: string) => {
  window.location.href = url;
};

function App() {
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  console.log("app.tsx");

  const handleCloseNavMenu = (page: any) => {
    navigate(page);
    setActiveTab(page);
  };

  return (
    <UserProvider>
      <div>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "white",
            justifyContent: "flex-start",
          }}
        >
          <Container maxWidth="xl" style={{ padding: "0%", margin: "0%" }}>
            <Toolbar
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  margin: "0",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flex: "start",
                }}
              >
                <img
                  src="UHC_Lockup_Horiz_blu_RGB.png"
                  alt="Logo"
                  style={{ height: "70%", marginLeft: "-5px" }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  style={{
                    color: "#1976d2",
                    fontWeight: 600,
                    marginTop: "3px",
                    marginLeft: "30px",
                  }}
                >
                  OPERA
                </Typography>
                
              </Box>

              <Button
                sx={{
                  color: "#1976d2",
                  textTransform: 'capitalize',
                  display: "flex",
                  alignItems: "center",
                  flex: "end",
                  fontWeight: '600',

                }}
                onClick = {() => {
                  openUserDetails("UserGuide.pdf")
                } }
              >
                User Guide
              </Button>
            </Toolbar>
          </Container>
        </AppBar>
        <AppBar position="static">
          <Container maxWidth="xl" style={{ padding: "0%", margin: "0%" }}>
            <Toolbar>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  onClick={() => handleExternalLinkClick(proshomeurl)}
                  sx={{ display: "flex", color: "white", marginBottom: "10px", gap: '5px' }}
                >
                  <CottageOutlined
                    style={{ marginBottom: "5px", marginLeft: "-10px" }}
                  />
                  <Typography noWrap>Provider Console</Typography>
                </Button>
              </Box>
              <Tabs
                value={false}
                sx={{
           
                  justifyContent: "flex-end",
                  display: 'center',
                 
                  width: "100%",
                }}
              >
                {DrfPages.map((DrfPage, index) => (
                  <Tab
                    key={DrfPage.page}
                    label={DrfPage.menudisplay}
                    onClick={() => handleCloseNavMenu(DrfPage.page)}
                    sx={{
                      color: activeTab === DrfPage.page ? "#f4f4f4" : "white",
                      borderBottom:
                        activeTab === DrfPage.page
                          ? " 2px solid white"
                          : "none",
                      display: "block",
                      height: "10px",
                      padding: "8px 16px",
                      margin: "0 5px",
                      transition: "border-bottom 0.3s ease-in-out",
                      cursor: "pointer",
                      fontWeight: activeTab === DrfPage.page ? 600 : 400,
                      "&:hover": { 
                        borderRadius: '8px',
                        background: 'rgba(255, 255, 255, 0.5)'
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Toolbar>
          </Container>
        </AppBar>

        <Routes>
          <Route path="/" element={<QueryData/>} />
        </Routes>
        <Routes>
          <Route
            path="/querydata"
            element={<QueryData/>}
          />
        </Routes>
        <Routes>
          <Route
            path="/adddata"
            element={<AddData/>}
          />
        </Routes>

        <Routes>
          <Route
            path="/oneintake"
            element={<OneIntake/>}
          />
        </Routes>
       
      </div>
    </UserProvider>
  );
}
export default App;
