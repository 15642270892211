import React, { useState, ChangeEvent } from "react";
import { useCookies } from "react-cookie";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import celenaservices from "../services/celenaservices";
import { FormHelperText } from "@mui/material";

interface Props {
  DTitle: String;
  TextLabel: String;
  open: boolean;
  onClose: () => void;
}

function LoginDialog(props: Props) {
  const [userMsId, setUserMsId] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [cookies, setCookie] = useCookies(["proscoreidentity"]);
  const [loginerror, setLoginError] = useState("");

  const handleUserMsIdTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserMsId(e.target.value);
  };
  const handleUserPwdTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserPwd(e.target.value);
  };
  const handleSaveLoginCookies = () => {
    if (userMsId == "" || userPwd == "") {
      setLoginError("Please provide correct user ms id and password.");
      return;
    }
    const usercredentials = { usermsid: userMsId, userpassword: userPwd };
    debugger;
    const response = celenaservices.generateJwtToken(usercredentials).then(
      (response) => {
        console.log('response.data.userRole:',response.data.userRole);
        if (
          typeof response.data.userRole != "undefined" &&
          response.data.userRole
        ) {
          console.log("jwtToken:", response.data.jwtToken);
          var yyyy = new Date().getFullYear();
          var yearend = new Date(yyyy + "-12-31");
          setCookie("proscoreidentity", response.data.jwtToken, {
            expires: yearend,
          });
          props.onClose();
        } else {
          setLoginError(
            "Please ensure that you are valid optum user."
          );
        }
      }
    );
  };
  const onKeyDownHandler = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      handleSaveLoginCookies();
    }
  };
  return (
    <Box>
      <Dialog open={props.open} onKeyDown={onKeyDownHandler}>
        <DialogTitle>Login</DialogTitle>

        <DialogContent>
          {loginerror && (
            <FormHelperText>
              <Typography variant="body2" color="error">
                <strong>{loginerror}</strong>
              </Typography>
            </FormHelperText>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="Extract"
            onChange={handleUserMsIdTextChange}
            value={userMsId}
            label={"User ID"}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="Extract"
            onChange={handleUserPwdTextChange}
            value={userPwd}
            label={"Password"}
            type={"password"}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveLoginCookies}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default LoginDialog;
